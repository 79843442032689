<template>
    <div class="main">
        <el-table :data="mainData.Data" style="width: 100%" height="450">
            <el-table-column prop="wishType" label="类型" width="180">
                <template slot-scope="scope">
                    <el-tag class="item" size="mini" :type="scope.row.WishType === 1 ? 'danger' : ''">{{ scope.row.WishType
                        ===
                        1 ? nameConfig.WDF : nameConfig.WCF }}</el-tag>
                    <!-- <div>{{ scope.row.WishType }}</div> -->
                </template>
            </el-table-column>
            <el-table-column prop="ZYName" label="志愿信息">
            </el-table-column>
            <el-table-column prop="Score" label="分数" width="100">
            </el-table-column>
            <el-table-column prop="Subjects" label="选科" width="200">
                <template slot-scope="scope">
                    <span v-show="scope.row.SubjectType==1">首选:物理</span>
                        <span v-show="scope.row.SubjectType==2">首选:历史</span>
                        <span v-show="scope.row.SubjectType"> 再选:</span>
                        <span>{{ scope.row.Subjects }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="BatchName" label="批次">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="look(scope.row)">查看</el-button>
                    <el-button size="mini" type="text" @click="modify(scope.row)">修改</el-button>
                    <el-button class="color-red" size="mini" type="text" @click="deleteItem(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <div class="list" v-for="(item, index) in mainData.Data" :key="index">
                <el-tag class="item" size="mini" :type="item.WishType === 1?'danger':''">{{ item.WishType === 1?'位点':'位次' }}</el-tag>
                <div class="item">{{ item.WishName }}</div>
                <div class="item">{{ item.Score }}</div>
            </div> -->
        <div class="pagination">
            <el-pagination layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]"
                :total="mainData.Total" @size-change="handleSize" @current-change="handlePage"></el-pagination>
        </div>
    </div>
</template>
<script>
import API from '@/api/config'
import {mapGetters} from 'vuex'
import { getStore, setStore } from '@/utils/utils'
export default {
    data() {
        return {
            mainData: {},
            query: {
                PageIndex: 1,
                PageSize: 10,
                // UserId: ''
            },
            studentInfo: '',
            totalData: [],
            ReportCheckData: [],
            studentReport:{}
        }
    },
    computed: {
        ...mapGetters(['nameConfig'])
    },
    mounted() {
        this.initialize()
    },
    methods: {
        // 修改
        modify(row) {

            // 处理参数
            // studentReport, RecommendBatch, SelectRecommendBatch


            this.studentReport = {
                ProvinceId: row.ExamProvinceId,
                ProvinceName: row.ExamProvinceName,
                Score: row.Score,
                SubjectList: row.Subjects.split(','),
                SubjectType: row.SubjectType,
                Year: row.ExamYear,
                wishCategory: row.ZYType,
                WishWay: row.ZYWay
            }

            let TP = getStore('ExamProvince')
            if (TP) {
                TP = JSON.parse(TP)
            }
            let ModifyProvince = TP.find(f=>f.ProvinceId==row.ExamProvinceId)
            
            setStore('studentReport', this.studentReport)
            setStore('modifyFlag', true)
            setStore('modifyProvinceObj', ModifyProvince)

            let recommendParams = {
                Rank: row.Rank,
                RankHigh: row.RankHigh,
                RankLow: row.RankLow,
                // RankYear: 
                // Score: 
            }

            let selectRecommendParams = {
                BatchId: row.BatchId,
                BatchName: row.BatchName,
                // BatchRemark:
                BatchScore: row.BatchScore,
                // BatchSort:
                // BatchYear:
                PlanYear: row.PlanYear,
                RecommendKey: row.RecommendKey,
                // RecommendVersion: row.RecommendVersion,
                // ScoreEqual: row.ScoreEqual || [],
                YearList: row.YearList || []
            }

            API.Recommend.GetRecommend(this.studentReport).then(res => {
                if (res.code == 0) {
                    // setStore('RecommendBatch', res.data)
                    // let temp = res.data.RecommendBatchList.find(f=>f.BatchId===row.BatchId)
                    
                    // setStore('SelectRecommendBatch', temp)

                    recommendParams.RankYear = res.data.RankYear
                    recommendParams.RecommendBatchList = res.data.RecommendBatchList

                    setStore('RecommendBatch', recommendParams)
                    let temp = res.data.RecommendBatchList.find(f=>f.BatchId===row.BatchId)

                    selectRecommendParams.ScoreEqual = temp.ScoreEqual
                    selectRecommendParams.BatchScore = temp.BatchScore
                    selectRecommendParams.BatchYear = temp.BatchYear
                    selectRecommendParams.RecommendVersion = temp.RecommendVersion
                    
                    setStore('SelectRecommendBatch', selectRecommendParams)


                    if (this.studentReport.WishWay===1) {
                        API.User.GetZYDetail({
                            Id: row.Id
                        }).then(res => {
                            this.ReportCheckData = res.data.RecordList
                            
                            this.ReportCheckData.forEach(item => {
                                item.SelectMajor = item.MajorList||[]
                            })
                            this.handleStore(row, '/recommend-index')
                        })
                    } else {
                        API.User.GetZYMajorAndCollegeDetail({
                            Id: row.Id
                        }).then(res => {
                            this.ReportCheckData = res.data.RecordList
                            this.handleStore(row, '/recommend-index')
                        })
                    }
                } else {
                    this.$message.error(res.message)
                }
            })

            

            
            // if (row.IsNewExam) { // 新高考

            //     this.getNewEXam(row)
            // } else { // 老高考

            //     this.storeMustStorage(row)

            // }
        },

        handleStore(row, router) {
            let obj = {}
            if (this.studentReport.WishWay === 1) {
                obj = {
                    1: this.ReportCheckData
                }
            }
            if (this.studentReport.WishWay === 2) {
                obj = {
                    2: this.ReportCheckData
                }
            }

            setStore('ReportCheckDataObj', obj)
            

            let url = this.$router.resolve({
                path: router,
                query: {
                    WishId: row.Id
                }
            })
            window.open(url.href, '_blank')
        },

        //存入老高考缓存必要数据
        storeMustStorage(row) {
            this.getWishDetails(row.Id).then((wishInfo) => {
                this.getTableHeaderInfo(wishInfo).then((tableInfo) => {
                    this.getEducationLevel(wishInfo).then((batchInfo) => {
                        const oldWish = {
                            ID: row.Id, // 志愿方案ID
                            OrgId: this.studentInfo.OrgId,
                            UserId: row.UserId,
                            BatchId: wishInfo.BatchId,
                            BatchName: wishInfo.BatchName,
                            ProvinceId: wishInfo.ExamProvinceId,
                            ProvinceName: wishInfo.ExamProvinceName,
                            Rank: wishInfo.RankLow,
                            Score: wishInfo.Score,
                            ScoreEqual: row.ScoreEqual,
                            SubjectType: wishInfo.SubjectType,
                            lineDifference: wishInfo.ScoreDiff,
                            stu_Id: this.studentInfo.Id,
                            tableHeadYear: tableInfo,
                            wishCategory: wishInfo.WishType,
                            EducationLevel: batchInfo.EducationLevel,
                        };
                        this.$setStorage("oldWish", oldWish).then(() => {
                            this.getCollegeInfo(0, wishInfo, oldWish);
                        });
                    });
                });
            });
        },
        // get education level query
        getEducationLevel(query) {
            return new Promise((resolve) => {
                API.Wish.BatchList({
                    Score: query.Score,
                    SubjectType: query.SubjectType,
                    ProvinceId: query.ExamProvinceId,
                }).then((res) => {
                    res.data.BatchList.some((item) => {
                        if (item.BatchId === query.BatchId) {
                            resolve(item);
                            return true;
                        }
                    });
                });
            });
        },
        //获取院校信息
        getCollegeInfo(i, wishInfo, oldWish) {
            wishInfo.WishSchoolList = wishInfo.WishSchoolList || [];
            if (i < wishInfo.WishSchoolList.length) {
                let searchQuery = {
                    ProvinceId: wishInfo.ExamProvinceId,
                    SubjectType: wishInfo.SubjectType,
                    BatchId: wishInfo.BatchId,
                };
                searchQuery.SchoolName = wishInfo.WishSchoolList[i].SchoolName;
                if (oldWish.wishCategory === 0) {
                    API.Wish.MySelfSearch(searchQuery).then((schoolInfo) => {
                        this.sureSchool(
                            searchQuery.SchoolName,
                            schoolInfo.data,
                            oldWish
                        ).then((collegeInfo) => {
                            this.getSpecialtyInfo(collegeInfo, oldWish).then(
                                (resSpecialtyList) => {
                                    this.compositeData(
                                        collegeInfo,
                                        wishInfo.WishSchoolList[i],
                                        resSpecialtyList
                                    ).then(() => {
                                        this.getCollegeInfo(++i, wishInfo, oldWish);
                                    });
                                }
                            );
                        }).catch(err => {
                            this.getCollegeInfo(++i, wishInfo, oldWish);
                        });
                    })
                } else {
                    API.Wish.MySelfSearchForecast(searchQuery).then((schoolInfo) => {

                        this.sureSchool(
                            searchQuery.SchoolName,
                            schoolInfo.data,
                            oldWish
                        ).then((collegeInfo) => {
                            this.getSpecialtyInfo(collegeInfo, oldWish).then(
                                (resSpecialtyList) => {
                                    this.compositeData(
                                        collegeInfo,
                                        wishInfo.WishSchoolList[i],
                                        resSpecialtyList
                                    ).then(() => {
                                        this.getCollegeInfo(++i, wishInfo, oldWish);
                                    });
                                }
                            );
                        }).catch(err => {
                            this.getCollegeInfo(++i, wishInfo, oldWish);
                        });
                    })
                }
            } else {
                // 递归迭代结束
                this.$ls.set('selectedOldWish', this.totalData)
                this.$router.push('/choose-wish')
            }
        },
        //通过学校名称找学校
        sureSchool(schoolName, schoolInfo, oldWish) {
            return new Promise((resolve, reject) => {
                let collegeQuery = {
                    ProvinceId: oldWish.ProvinceId,
                    SubjectType: oldWish.SubjectType,
                    BatchId: oldWish.BatchId,
                    Score: oldWish.Score,
                    ScoreEqual: oldWish.ScoreEqual,
                    // StudentId: oldWish.stu_Id
                };

                if (schoolInfo.length > 0) {
                    schoolInfo.some((item) => {
                        if (schoolName === item.SchoolName) {
                            collegeQuery.SchoolLineId = item.ID;
                            if (oldWish.wishCategory === 0) {
                                API.Wish.MySelf(collegeQuery).then((collegeInfo) => {
                                    if (collegeInfo.code === 0) {
                                        resolve(collegeInfo.data);
                                    } else {
                                        reject()
                                    }
                                }).catch(() => {
                                    reject()
                                });
                            } else {
                                API.Wish.MySelfForecast(collegeQuery).then((collegeInfo) => {
                                    if (collegeInfo.data) {
                                        if (collegeInfo.code === 0) {
                                            resolve(collegeInfo.data);
                                        } else {
                                            reject()
                                        }
                                    } else {
                                        reject()
                                    }
                                });
                            }
                            return true;
                        }
                    });
                } else {
                    reject()
                }
            });
        },
        //获取专业信息
        getSpecialtyInfo(collegeInfo, oldWish) {
            return new Promise((resolve) => {
                let specialtyQuery = {
                    ProvinceId: oldWish.ProvinceId,
                    Score: oldWish.Score,
                    ScoreEqual: oldWish.ScoreEqual,
                    SubjectType: oldWish.SubjectType,
                    SchoolLineId: collegeInfo.ID,
                    Sort: {}
                };
                if (oldWish.wishCategory === 0) {
                    // 位次法
                    API.Wish.SpecialtyList(specialtyQuery).then((specialtyInfo) => {
                        resolve(specialtyInfo.data);
                    });
                } else {
                    // 位点法
                    API.Wish.SpecialtyListForecast(specialtyQuery).then((specialtyInfo) => {
                        resolve(specialtyInfo.data);
                    });
                }
            });
        },
        //添加组合数据
        compositeData(college, wishSchool, specialtyList) {
            return new Promise((resolve) => {
                let selectedSpecialty = [];
                wishSchool.WishSpecialtyList.forEach((item) => {
                    specialtyList.some((subItem) => {
                        if (item.SpecialtyName === subItem.SpecialtyName) {
                            this.$set(subItem, "specialtySelectStatus", true);
                            selectedSpecialty.push(subItem);
                            return true;
                        }
                    });
                });
                this.$set(college, "WishCode", wishSchool.WishCode);
                this.$set(college, "IsTransfer", wishSchool.IsTransfer);
                this.$set(college, "selectedSpecialty", selectedSpecialty);

                this.totalData.push(college);
                resolve();
            });
        },
        //表格头部年份信息
        getTableHeaderInfo(query) {
            return new Promise((resolve) => {
                API.Wish.TableHeadYear({
                    ProvinceId: query.ExamProvinceId,
                    SubjectType: query.SubjectType,
                }).then((tableInfo) => {
                    resolve(tableInfo.data);
                });
            });
        },
        //获取志愿表详情
        getWishDetails(id) {
            return new Promise((resolve) => {
                API.User.WishDetail({
                    WishId: id,
                }).then((res) => {
                    resolve(res.data);
                });
            });
        },




        // 获取新高考详情数据
        getNewEXam(row) {
            /*专业院校组*/
            if (row.WishWay === 2) {
                API.User.WishSpecialtySchoolDetail({
                    WishId: row.Id,
                }).then((res) => {

                    if (res.data) {

                        this.setsearchData(res.data, row.Id);

                        const data = res.data.WishRecord;
                        // .map((item) => {
                        //   item.ID = item.SchoolId;
                        //   return item;
                        // });
                        this.$ls.set("data", data);
                        setTimeout(() => {
                            this.$router.push('/new-exam')
                        }, 0);
                    }
                });
            } else if (row.WishWay === 1) {
                /*院校专业组*/
                API.User.WishDetail({
                    WishId: row.Id,
                }).then((res) => {
                    if (res.data) {
                        this.setsearchData(res.data, row.Id);
                        const data = res.data.WishSchoolList;
                        // .map((item) => {
                        //   item.ID = item.SchoolId;
                        //   return item;
                        // });
                        this.$ls.set("dataList", data);
                        setTimeout(() => {
                            this.$router.push('/new-exam')
                        }, 0);
                    }
                });
            }
        },
        // 新高考数据存储
        setsearchData(row, Id) {

            // 新高考
            const searchData = {
                ID: Id,
                UserId: this.studentInfo.UserId,
                BatchId: row.BatchId,
                BatchName: row.BatchName,
                Rank: row.RankLow,
                // 1院校专业组 2专业院校组
                WishWay: row.WishWay,
                ProvinceId: row.ExamProvinceId,
                ProvinceName: row.ExamProvinceName,
                ScoreEqual: row.ScoreEqual,
                Score: row.Score,
                wishType: row.WishType,
                ScoreDiff: row.ScoreDiff,
                SelectExamSubjects: row.SelectSubjectList,
                Year: this.studentInfo.ExamYear,
            };
            this.$ls.set("searchData", searchData);
        },



        // 查看
        look(row) {

            this.studentReport = {
                ProvinceId: row.ExamProvinceId,
                ProvinceName: row.ExamProvinceName,
                Score: row.Score,
                SubjectList: row.Subjects.split(','),
                SubjectType: row.SubjectType,
                Year: row.ExamYear,
                wishCategory: row.ZYType,
                WishWay: row.ZYWay
            }
            
            let TP = getStore('ExamProvince')
            if (TP) {
                TP = JSON.parse(TP)
            }
            let ModifyProvince = TP.find(f=>f.ProvinceId==row.ExamProvinceId)
            
            setStore('studentReport', this.studentReport)
            setStore('modifyFlag', true)
            setStore('modifyProvinceObj', ModifyProvince)

            let recommendParams = {
                Rank: row.Rank,
                RankHigh: row.RankHigh,
                RankLow: row.RankLow,
                // RankYear: 
                // Score: 
            }

            let selectRecommendParams = {
                BatchId: row.BatchId,
                BatchName: row.BatchName,
                // BatchRemark:
                BatchScore: row.BatchScore,
                // BatchSort:
                // BatchYear:
                PlanYear: row.PlanYear,
                RecommendKey: row.RecommendKey,
                // RecommendVersion: row.RecommendVersion,
                // ScoreEqual: row.ScoreEqual || [],
                YearList: row.YearList || []
            }

            API.Recommend.GetRecommend(this.studentReport).then(res => {
                if (res.code == 0) {
                    // setStore('RecommendBatch', res.data)
                    // let temp = res.data.RecommendBatchList.find(f=>f.BatchId===row.BatchId)
                    
                    // setStore('SelectRecommendBatch', temp)

                    
                    recommendParams.RankYear = res.data.RankYear
                    recommendParams.RecommendBatchList = res.data.RecommendBatchList

                    setStore('RecommendBatch', recommendParams)
                    let temp = res.data.RecommendBatchList.find(f=>f.BatchId===row.BatchId)

                    selectRecommendParams.ScoreEqual = temp.ScoreEqual
                    selectRecommendParams.BatchScore = temp.BatchScore
                    selectRecommendParams.BatchYear = temp.BatchYear
                    selectRecommendParams.RecommendVersion = temp.RecommendVersion
                    
                    setStore('SelectRecommendBatch', selectRecommendParams)


                    if (this.studentReport.WishWay===1) {
                        API.User.GetZYDetail({
                            Id: row.Id
                        }).then(res => {
                            this.ReportCheckData = res.data.RecordList
                            this.ReportCheckData.forEach(item => {
                                item.SelectMajor = item.MajorList||[]
                            })
                            this.handleStore(row, '/wishtable')
                        })
                    } else {
                        API.User.GetZYMajorAndCollegeDetail({
                            Id: row.Id
                        }).then(res => {
                            this.ReportCheckData = res.data.RecordList
                            this.handleStore(row, '/wishtable')
                        })
                    }
                } else {
                    this.$message.error(res.message)
                }
            })

            // let url = this.$router.resolve({
            //     path: '/wishtable',
            //     query: {
            //         WishId: row.Id
            //     }
            // })
            // window.open(url.href, '_blank')

            
            
        },
        // delete
        deleteItem(row) {
            this.$confirm("您确定删除这份志愿表吗？", "注意！", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                lockScroll: true,
            }).then(() => {
                API.User.DelZY({ Id: row.Id }).then(() => {
                    this.getList()
                })
                // API.User.DelWish({ Id: row.Id }).then(() => {
                //     this.getList()
                // })
            }).catch(() => { })
        },
        // 分页
        handlePage(val) {
            this.query.PageIndex = val
            this.getList()
        },
        handleSize(val) {
            this.query.PageIndex = 1
            this.query.PageSize = val
            this.getList()
        },
        initialize() {

            let temp = getStore('LoginData')

            if(temp) {
                // this.query.UserId = JSON.parse(getStore('LoginData')).UserId
                this.studentInfo = JSON.parse(getStore('LoginData'))
            }

            this.getList()
        },

        // wish list
        getList() {
            API.User.GetZYList(this.query).then(res => {
                this.mainData = res.data
            })

            // API.User.GetWishList(this.query).then(res => {
            //     this.mainData = res.data
            // })
        }
    }
}
</script>
<style lang="less" scoped>
.main {
    padding: 0 40px;

    .list {
        margin-bottom: 20px;
        display: flex;
        color: #555;
        font-size: 15px;

        .item {
            margin-right: 15px;
        }
    }

    .pagination {
        margin-top: 10px;
    }

}

.color-red {
    color: #f56c6c;
}
</style>
<style lang="less">
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
</style>